<template>
  <div class="profile" v-if="permission.access">
    <b-row v-if="Object.keys(profileData).length">
      <b-col lg="4">
        <b-card>
          <div class="profile-img text-center">
            <b-img :src="profileData.image" />
          </div>
          <div class="profile-content text-center pt-1">
            <b-card-title style="margin-bottom: 13px">{{
              profileData.name
            }}</b-card-title>
            <b-card-sub-title>
              {{
                profileData.designation
                  ? profileData.designation
                  : "No data found"
              }}</b-card-sub-title
            >
            <p class="mt-1">
              {{
                profileData.career_objective
                  ? profileData.career_objective
                  : "No data found"
              }}
            </p>
            <div class="status text-right">
              <Dropdown
                style="margin-left: 20px"
                @on-click="updateCandidateIsApprove"
              >
                <Button
                  class="bg-primary text-white"
                  :class="{
                    'bg-success': profileData.is_approved == 'Approved',
                    'bg-primary': profileData.is_approved == 'Pending',
                    'bg-dnager': profileData.is_approved == 'Rejected',
                    'bg-info': profileData.is_approved == null,
                  }"
                >
                  {{
                    profileData.is_approved == null
                      ? "In review"
                      : profileData.is_approved
                  }}
                  <Icon type="ios-arrow-down"></Icon>
                </Button>
                <template #list>
                  <DropdownMenu v-if="permission.aprroved">
                    <DropdownItem name="1">Approved</DropdownItem>
                    <DropdownItem name="2">Pending</DropdownItem>
                    <DropdownItem name="3">Rejected</DropdownItem>
                    <DropdownItem name="4">In review</DropdownItem>
                  </DropdownMenu>
                </template>
              </Dropdown>
            </div>
          </div>

          <b-card-title class="d-flex justify-content-between pt-2"
            ><b>Preferences</b>
            <router-link
              :to="'/profile/' + id + '/preference' + '/edit'"
              v-if="permission.edit"
            >
              <button class="btn btn-primary btn-sm">
                <feather-icon icon="EditIcon" />
              </button>
            </router-link>
          </b-card-title>
          <b-row>
            <b-col lg="6" class="info_inner">
              <p class="title">Seniority</p>
              <p>
                <b>{{
                  profileData.seniority
                    ? profileData.seniority
                    : "No data found"
                }}</b>
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Workplace Type</p>
              <p>
                <b>{{
                  profileData.workplace_type
                    ? profileData.workplace_type
                    : "No data found"
                }}</b>
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Current Salary</p>
              <p>
                <b
                  >{{
                    profileData.current_salary
                      ? profileData.current_salary
                      : "No data found"
                  }}/-</b
                >
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Expected Salary</p>
              <p>
                <b
                  >{{
                    profileData.expected_salary
                      ? profileData.expected_salary
                      : "No data found"
                  }}/-</b
                >
              </p>
            </b-col>
            <b-col lg="6" class="info_inner">
              <p class="title">Availability</p>
              <p>
                <b>{{
                  profileData.availability
                    ? profileData.availability
                    : "No data found"
                }}</b>
              </p>
            </b-col>
          </b-row>
          <div class="info_inner">
            <p class="title">Preference Industries</p>
            <p>
              <b-badge
                v-for="(industry, index) in profileData.industries"
                :key="index"
                style="margin-right: 3px; margin-bottom: 3px"
              >
                {{ industry.name ? industry.name : "No data found" }}
              </b-badge>
            </p>
          </div>

          <div class="profile-skills pt-2">
            <b-card-title class="d-flex justify-content-between"
              ><b>Skills</b>
              <router-link
                :to="'/profile/' + id + '/skill' + '/edit'"
                v-if="permission.edit"
              >
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>

            <div
              class="info_inner"
              v-for="(skill, index) in sortedSkills"
              :key="index"
            >
              <p class="title">
                {{
                  skill.proficiency && skill.years_of_experience
                    ? skill.proficiency + " - " + skill.years_of_experience
                    : "No data found"
                }}
              </p>
              <p>
                <b>{{ skill.name ? skill.name : "No data found" }} </b>
              </p>
            </div>
          </div>

          <div class="pt-2">
            <b-card-title class="d-flex justify-content-between"
              ><b>CV Lists</b>
              <router-link
                :to="'/profile/' + id + '/cv' + '/edit'"
                v-if="permission.edit"
              >
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>
            <div
              class="info_inner d-flex justify-content-between align-items-center"
              v-for="(cv, index) in profileData.cvs"
              :key="index"
            >
              <p>
                <b>{{ cv.title }}</b>
              </p>
              <a
                :href="cv.cv"
                :download="cv.cv"
                class="btn btn-sm"
                :class="cv.status == true ? 'btn-success' : 'btn-secondary'"
              >
                <feather-icon icon="ArrowDownIcon" />
              </a>
            </div>
          </div>
        </b-card>
        <b-row>
          <b-col
            lg="12"
            v-if="Object.keys(profileData).length && threadPermission.access"
          >
            <ReviewComment :candidate_id="id" />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="8">
        <b-row>
          <b-col lg="9">
            <b-card>
              <b-card-title class="d-flex justify-content-between">
                <b>Basic Information</b>
                <router-link
                  :to="'/profile/' + id + '/basic-info' + '/edit'"
                  v-if="permission.edit"
                >
                  <button class="btn btn-primary btn-sm">
                    <feather-icon icon="EditIcon" />
                  </button>
                </router-link>
              </b-card-title>

              <b-row class="info_css">
                <b-col lg="4" class="info_inner">
                  <p class="title">PHONE</p>
                  <p>
                    <b>{{
                      profileData.phone ? profileData.phone : "No data found"
                    }}</b>
                  </p>
                </b-col>
                <b-col lg="4" class="info_inner">
                  <p class="title">Email</p>
                  <p>
                    <b>{{
                      profileData.email ? profileData.email : "No data found"
                    }}</b>
                  </p>
                </b-col>
                <b-col lg="4" class="info_inner">
                  <p class="title">On Board</p>
                  <p>
                    <span
                      class="badge"
                      :class="
                        profileData.onboard_status == 1
                          ? 'badge-primary'
                          : 'badge-danger'
                      "
                    >
                      {{
                        profileData.onboard_status == 1
                          ? "Completed"
                          : "In Complete"
                      }}
                    </span>
                  </p>
                </b-col>
                <b-col lg="4" class="info_inner">
                  <p class="title">Gender</p>
                  <p>
                    <b>{{
                      profileData.gender ? profileData.gender : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Secondary phone</p>
                  <p>
                    <b>{{
                      profileData.secondary_phone
                        ? profileData.secondary_phone
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Secondary email</p>
                  <p>
                    <b>{{
                      profileData.secondary_email
                        ? profileData.secondary_email
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Marital Status</p>
                  <p>
                    <b>{{
                      profileData.marital_status
                        ? profileData.marital_status
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Date Of Birth</p>
                  <p>
                    <b>{{
                      profileData.birth_date
                        ? profileData.birth_date
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Father name</p>
                  <p>
                    <b>{{
                      profileData.father_name
                        ? profileData.father_name
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Mother name</p>
                  <p>
                    <b>{{
                      profileData.mother_name
                        ? profileData.mother_name
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Religion</p>
                  <p>
                    <b>{{
                      profileData.religion
                        ? profileData.religion
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Blood Group</p>
                  <p>
                    <b>{{
                      profileData.blood_group
                        ? profileData.blood_group
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Nationality</p>
                  <p>
                    <b>{{
                      profileData.nationality
                        ? profileData.nationality
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Nid</p>
                  <p>
                    <b>{{
                      profileData.nid ? profileData.nid : "No data found"
                    }}</b>
                  </p>
                </b-col>

                <b-col lg="4" class="info_inner">
                  <p class="title">Passport number</p>
                  <p>
                    <b>{{
                      profileData.passport_number
                        ? profileData.passport_number
                        : "No data found"
                    }}</b>
                  </p>
                </b-col>
              </b-row>
              <div class="mt-1">
                <b-button variant="primary">Download Resume</b-button>
              </div>
            </b-card>
          </b-col>
          <b-col lg="3">
            <b-card no-body>
              <b-card-title
                class="d-flex justify-content-between align-items-center"
                style="padding: 5px"
              >
                <b><h5>Follow Up Note</h5> </b>
                <b-button size="sm" variant="primary" @click="getNoteModel"
                  >Add</b-button
                >
              </b-card-title>
              <div class="notes">
                <ul
                  ref="noteList"
                  v-if="notes.length > 0"
                  style="height: 250px; overflow-x: hidden"
                  :style="notes.length > 1 ? 'overflow-y:scroll' : ''"
                >
                  <li
                    class="p-1 rounded mb-1 main_class_message"
                    style="
                      list-style: none;
                      margin-right: 10px;
                      margin-left: 5px;
                    "
                    v-for="(note, index) in notes"
                    :key="index"
                    :class="{
                      'bg-primary text-white':
                        note.type == 'Note' && note.callstatus == null,
                      'bg-info text-white':
                        note.type != 'Note' && note.callstatus == null,
                      'bg-secondary text-white': note.callstatus != null,
                    }"
                  >
                    {{ note.note }}
                    <div class="text-right mt-1" v-if="note.followup_by">
                      <b>Note By</b> : {{ note.followup_by }}
                    </div>
                    <div class="text-right">
                      <small><b>Created At</b> : {{ note.created_at }} </small>
                    </div>
                  </li>
                </ul>
              </div>
            </b-card>
            <Modal
              v-model="note_model"
              :footer-hide="true"
              title="Create candidate Follow Up Note"
            >
              <b-form @submit.prevent="noteCreate">
                <div class="form-group">
                  <label>Note </label>
                  <Input
                    v-model="form.note"
                    placeholder="Enter your note"
                    style="width: 100%"
                    type="textarea"
                  />
                  <has-error :form="form" field="note"></has-error>
                </div>
                <div class="form-group">
                  <label>Note type</label>
                  <Select v-model="form.type" filterable>
                    <Option :value="1">Note</Option>
                    <Option :value="2">others</Option>
                  </Select>
                </div>
                <div class="form-group">
                  <label>Call Status</label>
                  <Select v-model="form.callstatus" filterable>
                    <Option :value="1">Celloff</Option>
                    <Option :value="2">Not responded</Option>
                    <Option :value="3">Number busy</Option>
                    <Option :value="4">Rejected</Option>
                  </Select>
                </div>
                <div class="d-flex justify-content-end align-items-center mt-2">
                  <div>
                    <b-button
                      variant="primary"
                      type="submit"
                      :disabled="form.busy"
                    >
                      Save
                    </b-button>
                  </div>
                </div>
              </b-form>
            </Modal>
          </b-col>
        </b-row>

        <b-card v-if="profileData.experiences">
          <div>
            <b-card-title class="d-flex justify-content-between"
              ><b>Experiences</b>
              <router-link
                :to="'/profile/' + id + '/experience' + '/edit'"
                v-if="permission.edit"
              >
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>
            <h6 v-if="profileData.experiences.length <= 0">
              {{ profileData.experiences != "" ? "" : "No data found" }}
            </h6>
            <app-timeline
                v-if="profileData.experiences[0].company_name"
            >
              <app-timeline-item
                v-for="(experience, index) in profileData.experiences"
                :key="index"
              >
                <div
                  class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-0"
                >
                  <h5>
                    {{
                      experience.designation
                        ? experience.designation
                        : "No data found"
                    }}
                  </h5>
                  <small class="text-muted" v-if="experience.duration"
                    >{{ experience.duration }} of Experience</small
                  >
                </div>
                <h5 class="mb-1">
                  {{
                    experience.company_name
                      ? experience.company_name
                      : "No data found"
                  }}
                </h5>
                <h6 class="card-subtitle mb-1 text-secondary">
                  ({{
                    experience.starting_date && experience.ending_date
                      ? experience.starting_date +
                        " - " +
                        experience.ending_date
                      : "No data found"
                  }})
                </h6>

                <p>
                  {{
                    experience.job_description
                      ? experience.job_description
                      : "No data found"
                  }}
                </p>
              </app-timeline-item>
            </app-timeline>
            <span v-else>No Experience</span>
          </div>
        </b-card>

        <b-card>
          <b-card-title class="d-flex justify-content-between"
            ><b>Education </b>
            <router-link
              :to="'/profile/' + id + '/education' + '/edit'"
              v-if="permission.edit"
            >
              <button class="btn btn-primary btn-sm">
                <feather-icon icon="EditIcon" />
              </button>
            </router-link>
          </b-card-title>
          <h6 v-if="profileData.educations.length <= 0">
            {{ profileData.educations != "" ? "" : "No data found" }}
          </h6>
          <b-row>
            <b-col
              lg="4"
              cols="12"
              md="6"
              v-for="(education, index) in profileData.educations"
              :key="index"
            >
              <div>
                <div class="card-body card border">
                  <div>
                    <h5 class="card-title font-weight-bolder font-weight-bold">
                      {{
                        education.institute
                          ? education.institute
                          : "No data found"
                      }}
                    </h5>
                  </div>

                  <div>
                    <h6 class="card-subtitle mb-1 text-secondary">
                      {{
                        education.degree ? education.degree : "No data found"
                      }}
                    </h6>
                  </div>

                  <div>
                    <h6 class="card-subtitle text-secondary">
                      {{
                        education.starting_date && education.ending_date
                          ? education.starting_date +
                            " - " +
                            education.ending_date
                          : "No data found"
                      }}
                    </h6>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <h4>
            {{ profileData.addresses != "" ? "" : "No addresses data yet" }}
          </h4>
          <div v-for="(address, index) in profileData.addresses" :key="index">
            <b-card-title class="d-flex justify-content-between"
              ><b>{{ address.title }}</b>
              <router-link
                :to="'/profile/' + id + '/basic-info' + '/edit'"
                v-if="index == 0 && permission.edit"
              >
                <button class="btn btn-primary btn-sm">
                  <feather-icon icon="EditIcon" />
                </button>
              </router-link>
            </b-card-title>
            <b-row>
              <b-col lg="3" class="info_inner">
                <p class="title">Division</p>
                <p>
                  <b>{{
                    address.division_name
                      ? address.division_name
                      : "No data found"
                  }}</b>
                </p>
              </b-col>
              <b-col lg="3" class="info_inner">
                <p class="title">District</p>
                <p>
                  <b>{{
                    address.district_name
                      ? address.district_name
                      : "No data found"
                  }}</b>
                </p>
              </b-col>
              <b-col lg="3" class="info_inner">
                <p class="title">Upazila</p>
                <p>
                  <b>{{
                    address.upazila_name
                      ? address.upazila_name
                      : "No data found"
                  }}</b>
                </p>
              </b-col>
              <b-col lg="3" class="info_inner">
                <p class="title">Country</p>
                <p>
                  <b>{{
                    address.is_country ? address.is_country : "No data found"
                  }}</b>
                </p>
              </b-col>
              <b-col lg="12" class="info_inner">
                <p class="title">Address</p>
                <p>
                  <b>{{
                    address.address ? address.address : "No data found"
                  }}</b>
                </p>
              </b-col>
            </b-row>

            <hr />
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardSubTitle,
  BImg,
  BForm,
  BButton,
  BBadge,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import ReviewComment from "./ReviewComment.vue";
import { HasError } from "vform/src/components/bootstrap5";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BImg,
    BForm,
    BButton,
    BBadge,
    AppTimeline,
    AppTimelineItem,
    ReviewComment,
    HasError,
  },
  data() {
    return {
      id: this.$route.params.id,
      profileData: [],
      form: new Form({
        note: null,
        callstatus: null,
        candidate_id: this.$route.params.id,
        type: null,
      }),
      notes: [],
      note_model: false,
    };
  },
  mounted() {
    this.getProfile();
    this.getNotes();
  },
  methods: {
    getNoteModel() {
      this.note_model = true;
    },

    getNotes() {
      axios
        .get("app/followup/" + this.id)
        .then((res) => {
          this.notes = res.data.data;
          this.scrollToBottom();
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    noteCreate() {
      this.form
        .post("app/followup")
        .then((res) => {
          this.s(res.data.message);
          this.getNotes();
          this.note_model = false;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    getProfile() {
      axios
        .get("app/candidate/profile/" + this.id)
        .then((res) => {
          this.profileData = res.data.data;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    updateCandidateIsApprove(value) {
      axios
        .put("/app/candidate/profile/status/" + this.profileData.id, {
          is_approved: value,
        })
        .then((res) => {
          this.getProfile();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const noteList = this.$refs.noteList;
        if (noteList) {
          noteList.scrollTop = noteList.scrollHeight;
        }
      });
    },
  },
  computed: {
    sortedSkills() {
      const proficiencyOrder = {
        EXPERT: 0,
        EXPERIENCED: 1,
        ADVANCED: 2,
        BEGINNER: 3,
      };
      return this.profileData?.skills?.slice().sort((a, b) => {
        return (
          proficiencyOrder[a.proficiency] - proficiencyOrder[b.proficiency]
        );
      });
    },
    permission() {
      return this.$store.getters.getPermissions.candidate;
    },

    threadPermission() {
      return this.$store.getters.getPermissions.thread;
    },
  },
};
</script>

<style scoped>
.profile .profile-img img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}
.profile .profile-skills .skill,
.industries {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}
.experience-card {
  background-color: #fafafa;
}

.designation {
  color: #0c389f;
}

.highlight {
  background: #4ec76e;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
}
.cv h3 {
  color: #fff !important;
}

.info_inner {
  margin-bottom: 5px;
}
.info_inner .title {
  margin-bottom: 2px;
  color: #979797;
  font-weight: 450;
  text-transform: uppercase;
}
.experience-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: #000;
  border-radius: 50%;
}
.experience-logo h2 {
  text-transform: uppercase;
  font-weight: bold;
  color: #ffff;
}

.notes ul::-webkit-scrollbar-track {
  border: 4px solid white;

  background-color: #ffffff;
}

.notes ul::-webkit-scrollbar {
  width: 15px;
  background-color: #ffffff;
}

.notes ul::-webkit-scrollbar-thumb {
  background-color: #357cc4;
  border-radius: 10px;
  border: solid white 5px;
}

.notes ul::-webkit-scrollbar-thumb:hover {
  background-color: #2c5d8b;
}

.notes ul::-webkit-scrollbar-button {
  color: #357cc4;
  background-color: #2c5d8b;
  border: solid white 5px;
  border-radius: 10px;
}
.notes ul::-webkit-scrollbar-button:hover {
  border: solid white 2px;
}
</style>
