<template>
  <div>
    <b-card v-if="permission.access">
      <b-card-title class="d-flex justify-content-between"
        ><b>Review & Massage </b>
        <b-button
          size="sm"
          variant="primary"
          @click="getThredCreateModel"
          v-if="permission.manage"
          >Add</b-button
        >
      </b-card-title>
      <ul>
        <li
          style="padding: 7px 15px 7px 15px"
          v-for="(thread, index) in threads"
          :key="index"
          v-if="threads.length>0"
          class="d-flex justify-content-between mt-1 border align-items-center rounded"
        >
          <p class="mb-0">
            <b>{{ thread.title }}</b>
          </p>
          <p class="mb-0">
            <span>{{ thread.created_at | date_format }}</span>
            <Dropdown
              style="margin-left: 20px"
              @on-click="threadOpenClose(thread.status == 1 ? 2 : 1, thread.id)"
            >
              <b-button
                size="sm"
                class="badge text-white ml-1 mr-1"
                style="border: none"
                :class="thread.status == 1 ? 'bg-success' : 'bg-danger'"
              >
                {{ thread.status == 1 ? "Open" : "Close" }}
                <Icon type="ios-arrow-down"></Icon>
              </b-button>
              <template #list>
                <DropdownMenu>
                  <DropdownItem>
                    {{ thread.status !== 1 ? "Open" : "Close" }}</DropdownItem
                  >
                </DropdownMenu>
              </template>
            </Dropdown>
            <b-button
              size="sm"
              variant="primary"
              style="padding: 4px 10px"
              :disabled="thread.status == 2"
              @click="getMessage(thread.id)"
            >
              <feather-icon icon="MessageSquareIcon" size="16"
            /></b-button>
          </p>
        </li>
      </ul>
    </b-card>

    <Modal v-model="thred_model" :footer-hide="true">
      <b-form @submit.prevent="!form.id ? Create() : Update(form.id)">
        <div class="form-group">
          <label> Title </label>
          <Input
            v-model="form.title"
            placeholder="Enter your title"
            style="width: 100%"
            type="text"
          />
          <has-error :form="form" field="title"></has-error>
        </div>
        <div class="form-group">
          <label>Message </label>
          <Input
            v-model="form.message"
            placeholder="Enter your message"
            style="width: 100%"
            type="textarea"
          />
          <has-error :form="form" field="message"></has-error>
        </div>
        <div class="d-flex justify-content-end align-items-center mt-2">
          <div>
            <b-button variant="primary" type="submit" :disabled="form.busy">
              {{ form.id ? "Update" : "Add" }}
            </b-button>
          </div>
        </div>
      </b-form>
    </Modal>
    <Modal v-model="message_model" :footer-hide="true" :title="form.title">
      <ul
      ref="messageList"
        v-if="messages.messages"
        style="height: 400px; overflow-x: hidden"
        :style="messages.messages.length > 5 ? 'overflow-y:scroll' : ''"
      >
        <li
          class="p-1 rounded mb-1 main_class_message"
          :class="
            message.message_by == null ? 'bg-light' : 'bg-primary text-white'
          "
          style="list-style: none; margin-right: 10px; margin-left: 5px"
          v-for="(message, index) in messages.messages"
          :key="index"
        >
          <div
            class="text-danger custom_delete"
            v-if="message.message_by !== null && permission.manage"
            @click="Delete(message.id)"
          >
            <feather-icon icon="TrashIcon" size="16" />
          </div>
          {{ message.message }}
          <div class="text-right mt-1" v-if="message.message_by">
            <b>Message By</b> : {{ message.message_by.name }}
          </div>
          <div class="text-right">
            <b>Created At</b> : {{ message.created_at | date_format }}
          </div>
        </li>
      </ul>
      <Input
        v-if="permission.manage"
        v-model="form.message"
        placeholder="Enter something..."
        style="width: 405px"
      />
      <Button
        class="ml-1"
        type="primary"
        @click="sendMessage"
        v-if="permission.manage"
        >Send</Button
      >
    </Modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BCardTitle,
} from "bootstrap-vue";
import { HasError } from "vform/src/components/bootstrap5";

export default {
  props: ["candidate_id"],
  data() {
    return {
      form: new Form({
        id: null,
        title: null,
        message: null,
        candidate_id: this.candidate_id,
        candidate_thread_id: null,
        status: null,
      }),
      threads: [],
      messages: [],
      thred_model: false,
      message_model: false,
    };
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    HasError,
    BCard,
    BCardTitle,
  },
  mounted() {
    this.getThreads();
    this.scrollToBottom();
  },
  methods: {
    Clear() {
      this.form.id = null;
      this.form.title = null;
      this.form.message = null;
      this.form.candidate_thread_id = null;
    },
    getThredCreateModel() {
      this.Clear();
      this.thred_model = true;
    },

    getThreads() {
      axios
        .get("/app/candidate/thread/" + this.form.candidate_id)
        .then((res) => {
          this.threads = res.data.candidate_threads;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    getMessage(id) {
      this.form.candidate_thread_id = id;
      axios
        .get("/app/candidate/message/" + id)
        .then((res) => {
          this.messages = res.data.messages;
          this.form.title = res.data.messages.thread.title;
          this.message_model = true;
          this.scrollToBottom();
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Create() {
      this.form
        .post("/app/candidate/message")
        .then((res) => {
          this.getThreads();
          this.Clear();
          this.thred_model = false;
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    sendMessage() {
      this.form
        .post("/app/candidate/message")
        .then((res) => {
          this.getMessage(this.form.candidate_thread_id);
          this.s(res.data.message);
          this.form.message = null;
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    threadOpenClose(value, threadId) {
      this.form.status = value;
      this.form
        .put("/app/candidate/message/" + threadId)
        .then((res) => {
          this.getThreads();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    Delete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete("/app/candidate/message/" + id)
            .then((res) => {
              this.s(res.data.message);
              this.getMessage(this.form.candidate_thread_id);
            })
            .catch((e) => {
              this.e(e.response.data.message);
            });
        }
      });
    },

    scrollToBottom() {
    this.$nextTick(() => {
      const messageList = this.$refs.messageList;
      if (messageList) {
        messageList.scrollTop = messageList.scrollHeight;
      }
    });
  },
  },
  computed: {
    permission() {
      return this.$store.getters.getPermissions.thread;
    },
  },
};
</script>

<style scoped>
.main_class_message {
  position: relative;
}
.custom_delete {
  position: absolute;
  background: #ddd;
  width: 25px;
  height: 25px;
  top: -5px;
  right: -5px;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
}
</style>
